import React, { useContext } from "react";
import PropTypes from "prop-types";
import NavbarWrapper from "common/components/Navbar";
import Drawer from "common/components/Drawer";
import Logo from "common/components/UIElements/Logo";
import Box from "common/components/Box";
import HamburgMenu from "common/components/HamburgMenu";
import Container from "common/components/UI/Container";
import { DrawerContext } from "common/contexts/DrawerContext";
import { MENU_ITEMS } from "common/data/Saas";
import ScrollSpyMenu from "common/components/ScrollSpyMenu";
const Navbar = ({
					navbarStyle,
					logoStyle,
					row,
					menuWrapper
				}) => {
	const {
		state,
		dispatch
	} = useContext(DrawerContext);
	// Toggle drawer
	const toggleHandler = () => {
		dispatch({
			type: "TOGGLE"
		});
	};
	return (
		<NavbarWrapper { ...navbarStyle }>
			<Container>
				<Box { ...row }>
					<Logo
						href="#"
						//            logoSrc={LogoImage}
						titleStyle={ {
							...Logo.defaultProps.titleStyle,
							mr: "1.25rem",
							mb: "0.625rem"
						} }
						title="Narkuma"
						logoStyle={ logoStyle }
					/>
					<Box { ...menuWrapper }>
						<ScrollSpyMenu
							className="main_menu"
							menuItems={ MENU_ITEMS }
							offset={ -70 }
						/>
						<Drawer
							width="420px"
							placement="right"
							drawerHandler={ <HamburgMenu barColor="#eb4d4b"/> }
							open={ state.isOpen }
							toggleHandler={ toggleHandler }
						>
							<ScrollSpyMenu
								className="mobile_menu"
								menuItems={ MENU_ITEMS }
								drawerClose={ true }
								offset={ -100 }
							/>
						</Drawer>
					</Box>
				</Box>
			</Container>
		</NavbarWrapper>
	);
};
Navbar.propTypes = {
	navbarStyle: PropTypes.object,
	logoStyle: PropTypes.object,
	button: PropTypes.object,
	row: PropTypes.object,
	menuWrapper: PropTypes.object
};
Navbar.defaultProps = {
	navbarStyle: {
		className: "hosting_navbar",
		minHeight: "70px",
		display: "block"
	},
	row: {
		flexBox: true,
		alignItems: "center",
		justifyContent: [
			"space-between",
			"space-between",
			"space-between",
			"flex-start"
		],
		width: "100%"
	},
	logoStyle: {
		maxWidth: "130px",
		mr: [0, 0, 0, "166px"]
	},
	button: {
		type: "button",
		fontSize: "13px",
		fontWeight: "600",
		color: "white",
		borderRadius: "4px",
		pl: "15px",
		pr: "15px",
		colors: "primaryWithBg",
		minHeight: "auto",
		height: `${ 1 }`
	},
	menuWrapper: {
		flexBox: true,
		alignItems: "center"
	}
};
export default Navbar;
